<template lang="pug">
	v-dialog(v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel")
			v-toolbar(dark :color="options.color" dense flat)
				v-toolbar-title.text-body-1
					| {{ $t('confirmDialogRenew.title') }}
			v-card
				v-card-text.pa-4.text-body-2
					| {{ $t('confirmDialogRenew.message') }}
					v-container
						v-row
							v-col(v-for="channel in channels" :key="channel")
								v-checkbox(
									:label="$te(`channel_list.${channel}`) ? $t(`channel_list.${channel}`) : channel" 
									:value="channel" v-model="selected")
				v-card-actions
					v-spacer
					v-btn.body-2.font-weight-bold(v-if="!options.noconfirm" color="grey" text @click.native="cancel")
						| {{ this.$t('confirmDialog.decline') }}
					v-btn.body-2.font-weight-bold(v-if="!options.noconfirm" color="primary" outlined @click.native="agree")
						| {{ this.$t('confirmDialog.accept') }}
</template>

<script>
export default {
	name: 'ConfirmDialogRenew',
	data () {
		return {
			dialog: false,
			btnclose: false,
			resolve: null,
			reject: null,
			options: {
				width: '300px',
				zIndex: 200,
				noconfirm: false,
				color: 'primary'
			},
			selected: ['road']
		}
	},
	computed: {
		channels() {
			return [...new Set(this.castzones.flatMap(cz => cz.bulletins ? Object.keys(cz.bulletins) : []))]
		}
	},
	watch: {
		dialog(open) {
			if(open) this.selected = ['road']
			else if(!this.btnclose) this.resolve(false)
		}
	},  
	methods: {
		confirm(options) {
			this.dialog = true
			this.options = Object.assign({
				width: '500px',
				zIndex: 200,
				noconfirm: false,
				color: 'primary'
			}, options)
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		agree() {
			this.resolve(this.selected && this.selected.length ? this.selected : false )
			this.btnclose = true
			this.dialog = false
		},
		cancel() {
			this.resolve(false)
			this.btnclose = true
			this.dialog = false
		},
	},
}
</script>

<style scoped>
</style>
